<template>
  <Modal :value="visible"
         :title="title"
         footer-hide
         width="880"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <Table ref="table"
           :columns="columns"
           :data="data"
           height="380"
           border
           stripe
           :loading="tableLoading">
      <template slot-scope="{ row }"
                slot="operation">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickEditVideoChannel(row)">修改</Button>
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickStatusVideoChannel(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickDeleteVideoChannel(row)">删除</Button>
      </template>
    </Table>
    <div class="modal-footer m-t-10">
      <Button type="text"
              class="tableOperaBtn"
              @click.stop="equipmentVideoChannelVisible=true">
        <Icon custom="i-icon icon-tianjiabiankuang"
              size="16"></Icon>
        添加
      </Button>
    </div>
    <!-- 新增 修改 -->
    <EquipmentVideoChannelModal v-model="equipmentVideoChannelVisible"
                                :dataId.sync="equipmentVideoChannelId"
                                :parentId="dataId"
                                @onClickConfirm="onClickConfirm"></EquipmentVideoChannelModal>
  </Modal>
</template>

<script>
import EquipmentVideoChannelModal from '@/components/product/admin/modal/archives/EquipmentVideoChannelModal'
export default {
  components: {
    EquipmentVideoChannelModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '通道列表'
    },
    dataId: String
  },
  data () {
    return {
      data: [],
      columns: [
        {
          title: '序号',
          type: 'index',
          width: 45,
          align: 'center'
        },
        {
          title: '通道名称',
          key: 'channelname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '通道id',
          key: 'channelid',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '通道排序',
          key: 'sort',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      tableLoading: false,
      // 新增 修改
      equipmentVideoChannelVisible: false,
      equipmentVideoChannelId: ''
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getList()
    }
  },
  methods: {
    getList () {
      this.tableLoading = true
      this.$http.getVideoEquipmentChannelList({ equipid: this.dataId }).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击修改设备
    onClickEditVideoChannel (row) {
      this.equipmentVideoChannelId = row.id
      this.equipmentVideoChannelVisible = true
    },
    // 点击删除设备
    onClickDeleteVideoChannel (row) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除？',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteEquipmentVideoChannel(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
            }
          })
        }
      })
    },
    // 修改设备状态
    onClickStatusVideoChannel (row) {
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditEquipmentVideoChannel', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditEquipmentVideoChannel', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 点击确认
    onClickConfirm () {
      this.getList()
      this.$emit('onClickConfirm')
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.data = []
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  text-align: left;
}
</style>
