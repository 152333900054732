<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>厂商名称：</p>
          <Input v-model="name"
                 placeholder="厂商名称" maxlength="30" v-stringLimit></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '厂商'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '', // 厂商名称
      remark: '', // 备注
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getEquipmentManufacturerDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 点击确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入厂商名称');
      this.loading = true
      let params = {
        name: this.name,
        remark: this.remark
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditEquipmentManufacturer', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新厂商下拉框
        this.$store.dispatch('updateEquipmentManufacturerAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.name = ''
        this.remark = ''
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
