<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备型号：</p>
          <Input v-model="model"
                 placeholder="设备型号"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备类型：</p>
          <Select v-model="type"
                  placeholder="设备类型">
            <Option :value="item.id"
                    v-for="item in equipmentTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>厂商：</p>
          <Select v-model="manufacturer"
                  placeholder="厂商">
            <Option :value="item.id"
                    v-for="item in manufacturerArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>协议：</p>
          <Select v-model="agreement"
                  placeholder="协议">
            <Option :value="item.id"
                    v-for="item in agreementArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">常用指令：</p>
          <Input v-model="instruct"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设备型号'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      model: '', // 设备型号
      type: '', // 设备类型
      manufacturer: '', // 厂商
      agreement: '', // 协议
      instruct: '', // 常用指令
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'equipmentTypeArr': 'getEquipmentTypeAllList',
      'manufacturerArr': 'getEquipmentManufacturerAllEnableList',
      'agreementArr': 'getAgreementArr',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateEquipmentTypeAllList',
      'updateEquipmentManufacturerAllEnableList',
    ]),
    init () {
      // equipmentArchives已调用，防重复调用
      // this.updateEquipmentTypeAllList()
      this.updateEquipmentManufacturerAllEnableList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getEquipmentModelDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
      })
    },
    // 点击确认
    onClickConfirm () {
      if (!this.model) return this.$Message.info('请输入设备型号');
      if (!this.type) return this.$Message.info('请选择设备类型');
      if (!this.manufacturer) return this.$Message.info('请选择厂商');
      if (!this.agreement) return this.$Message.info('请选择协议');
      let params = {
        model: this.model,
        type: this.type,
        manufacturer: this.manufacturer,
        agreement: this.agreement,
        instruct: this.instruct,
      }
      if (this.dataId) params.ids = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditEquipmentModel', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.model = ''
      this.type = ''
      this.manufacturer = ''
      this.agreement = ''
      this.instruct = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
