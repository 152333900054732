<template>
  <Modal :value="visible"
         :title="title"
         width="740"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <ul class="equipment-cont"
          v-if="item&&item.bindobjid">
        <li>
          <div class="flex a-center">
            <Icon custom="i-icon icon-jingshi"
                  size="18"
                  color="#F68040" />
            <span>设备绑定对象：{{item.bindobjname}}{{item.communicationcode?'('+item.communicationcode+')':''}} {{item.binddeptname}}</span>
          </div>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickUnbind">解绑</Button>
        </li>
      </ul>
      <div class="equipment-filtrate">
        <Select v-model="bigType"
                placeholder="大类"
                style="width:170px"
                class="m-r-5">
          <Option v-for="item in bigTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="smallType"
                placeholder="小类"
                @on-change="onChangeSmallType"
                style="width:170px"
                class="m-r-5">
          <Option v-for="item in smallTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="status"
                placeholder="是否绑定"
                @on-change="onChangeStatus"
                style="width:170px"
                class="m-r-5">
          <Option v-for="item in statusArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <MyTreeSelect :value="name"
                      placeholder="对象名称"
                      clearable
                      class="m-r-5"
                      style="width:170px"
                      :data="nameArr"
                      search
                      @onSelectChange="onChangeName">
        </MyTreeSelect>
      </div>
      <Table ref="table"
             :columns="columns"
             :data="data"
             height="320"
             border
             stripe
             :loading="tableLoading"
             @on-row-click="onClickBind">
      </Table>
      <Page class="m-t-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
export default {
  name: 'equipmentBindingModal',
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定对象'
    },
    item: null
  },
  data () {
    return {
      bigType: '',
      smallType: '',
      smallTypeArr: [],
      status: '',
      statusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未绑定'
        },
        {
          id: 2,
          name: '已绑定'
        },
      ],
      name: '',
      nameArr: [],
      data: [],
      columns: [
        {
          title: '对象名称',
          key: 'objname',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '类型',
          key: 'itemname',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      // 绑定设备
      bindConfig: {
        title: '是否确认绑定',
        content: '正确绑定后，该设备将上传数据！'
      },
      // 解绑设备
      unbindConfig: {
        title: '是否确认此操作',
        content: '解绑后，该对象不再发送此类数据'
      },
      // 绑定并替换
      bindAndReplaceConfig: {
        title: '是否解绑并替换',
        content: '正确绑定后，该设备将上传数据！'
      },
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
    bigType (newVal) {
      this.onChangeBigType(newVal)
    },
    name (newVal) {
      if (!newVal) {
        this.getList()
      }
    }
  },
  computed: {
    bigTypeArr () {
      let arr = [
        {
          id: 'car',
          name: '车辆'
        },
        {
          id: 'emp',
          name: '人员'
        },
      ]
      return arr.concat(this.$store.getters.getFacilityBigTypeAllList)
    },
  },
  mounted () {
    // this.init()
    this.updateFacilityBigTypeAllList()
  },
  methods: {
    ...mapActions([
      'updateFacilityBigTypeAllList',
    ]),
    init () {
      this.bigType = 'car'
      this.status = ''
      // this.onChangeBigType(this.bigType)
    },
    // 修改大类
    onChangeBigType (value) {
      if (value) {
        let arr = [{
          id: 0,
          name: '全部'
        }]
        switch (value) {
          case 'car':
            this.$http.getCarTypeAllList().then(res => {
              if (res.code === 200) {
                this.smallTypeArr = arr.concat(res.result)
              }
            })
            break;
          case 'emp':
            this.$http.getWorkTypeAllList().then(res => {
              if (res.code === 200) {
                this.smallTypeArr = arr.concat(res.result)
              }
            })
            break;
          default:
            this.$http.getFacilitySmallTypeAllList({ facilitietypeid: value }).then(res => {
              if (res.code === 200) {
                this.smallTypeArr = arr.concat(res.result)
              }
            })
            break;
        }
      } else {
        this.smallTypeArr = []
      }
      this.smallType = ''
      this.name = ''
      this.getList()
    },
    onChangeSmallType () {
      this.name = ''
      this.getList()
    },
    onChangeStatus () {
      this.name = ''
      this.getList()
    },
    // 修改姓名
    onChangeName (data) {
      this.name = data.name
      this.getList()
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: this.bigType,
        item: '',
        status: '',
        objname: this.name
      }
      if (this.smallType) params.item = this.smallType
      if (this.status) params.status = this.status === 1 ? 0 : 1
      this.tableLoading = true
      this.$http.getBindEquipmentList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.rows
          this.totalPage = res.result.total
          if (!this.name) {
            this.nameArr = res.result.map(item => {
              return {
                id: item.id,
                name: item.objname,
                type: item.type === 'car' ? 'car' : item.type === 'emp' ? 'emp' : 'facl'
              }
            })
          }
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击绑定
    onClickBind (row) {
      // let status
      // if (this.item.bindobjid) {
      //   status = 2
      // } else {
      //   status = 1
      // }
      this.onBindEquipment(row, 1)
    },
    // 点击解绑
    onClickUnbind () {
      this.onBindEquipment(null, 0)
    },
    // 绑定设备
    onBindEquipment (obj, status) {
      let config
      if (this.item.bindobjid) {
        config = status === 0 ? this.unbindConfig : this.bindAndReplaceConfig
      } else {
        config = this.bindConfig
      }
      this.$Modal.confirm({
        ...config,
        onOk: () => {
          let params = {
            data: {
              status: status,
              equipid: this.item.id,
              equiptype: this.item.equiptypeid,
              equipcode: this.item.communicationcode,
              equiptypename: this.item.equiptypeid + '(' + this.item.communicationcode + ')',
              itemid: '',
              itemname: '',
              type: '',
              typeitem: '',
              itemdept: '',
            }
          }
          if (status === 1) {
            params.data.itemid = obj.id
            params.data.itemname = obj.objname
            params.data.type = obj.type
            params.data.typeitem = obj.item
            params.data.itemdept = obj.deptid
          }
          this.$http.equipmentBind(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.$emit('onClickConfirm')
              this.$emit('onChange', false)
            }
          })
        }
      })
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  .equipment-cont {
    background: #f2f2f2;
    padding: 10px;
    margin: 10px 0;
    overflow-y: scroll;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #353639;
    }
    .binding {
      padding: 4px !important;
      background-color: #fff;
      border: 1px solid #dddddd;
      margin: 11px 5px 11px 0;
      height: 22px;
      .binding-icon {
        cursor: pointer;
      }
    }
  }
  .equipment-filtrate {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
