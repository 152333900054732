<template>
  <Modal :value="visible"
         :title="title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>请求地址：</p>
          <Input v-model="requestUrl"
                 placeholder="请求地址"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>账号：</p>
          <Input v-model="account"
                 placeholder="账号"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>密码：</p>
          <Input v-model="password"
                 placeholder="密码"></Input>
        </li>
      </ul>
    </div>
    <div slot="footer"
         class="modal-footer">
      <Button type="primary">测试协议</Button>
      <div>
        <Button @click.stop="onClickCancel">取消</Button>
        <Button type="primary"
                @click.stop="onClickConfirm">确定</Button>
      </div>
    </div>

  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '协议配置'
    },
    dataId: String
  },
  data () {
    return {
      requestUrl: '', // 请求地址
      account: '', // 账号
      password: '', // 密码
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取详情
    getDetail () {

    },
    // 点击确认
    onClickConfirm () {
      if (!this.requestUrl) return this.$Message.info('请输入请求地址');
      if (!this.account) return this.$Message.info('请输入账号');
      if (!this.password) return this.$Message.info('请输入密码');
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.requestUrl = ''
        this.account = ''
        this.password = ''
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
