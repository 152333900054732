<template>
  <Modal :value="visible"
         :title="title"
         footer-hide
         width="800"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div>
      <Table ref="table"
             :columns="columnsList"
             :data="data"
             height="400"
             border
             stripe
             :loading="tableLoading">
        <template slot-scope="{ row }"
                  slot="operation">
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickEditManufacturer(row)">修改</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickStatusManufacturer(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickDeleteManufacturer(row)">删除</Button>
        </template>
      </Table>
      <div class="modal-footer m-t-10">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="manufacturerVisible=true">
          <Icon custom="i-icon icon-tianjiabiankuang"
                size="16"></Icon>
          添加
        </Button>
      </div>
    </div>
    <!-- 新增 修改 -->
    <EquipmentManufacturerModal v-model="manufacturerVisible"
                                :dataId.sync="manufacturerDataId"
                                @onClickConfirm="getList"></EquipmentManufacturerModal>
  </Modal>
</template>

<script>
import EquipmentManufacturerModal from '@/components/product/admin/modal/archives/EquipmentManufacturerModal'
export default {
  components: {
    EquipmentManufacturerModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '厂商设置'
    }
  },
  data () {
    return {
      data: [],
      columnsList: [
        {
          title: '厂商名称',
          key: 'manufacturername',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      // 修改
      manufacturerVisible: false,
      manufacturerDataId: ''
    }
  },
  watch: {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getList()
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        dicname: '',
        status: '',
      }
      this.tableLoading = true
      this.$http.getEquipmentManufacturerList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.manufacturerlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改
    onClickEditManufacturer (row) {
      this.manufacturerVisible = true
      this.manufacturerDataId = row.id
    },
    // 修改状态
    onClickStatusManufacturer (row) {
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditEquipmentManufacturer', params).then(res => {
              this.getList()
              this.$store.dispatch('updateEquipmentManufacturerAllEnableList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditEquipmentManufacturer', params).then(res => {
              this.getList()
              this.$store.dispatch('updateEquipmentManufacturerAllEnableList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 删除
    onClickDeleteManufacturer (row) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteEquipmentManufacturer(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getList()
              this.$store.dispatch('updateEquipmentManufacturerAllList', true)
            }
          })
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  text-align: left;
}
</style>
