<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备编号：</p>
          <Input v-model="number"
                 placeholder="设备编号"
                 maxlength="30"
                 v-charAndNumberLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备通讯号：</p>
          <Input v-model="communication"
                 placeholder="设备通讯号"
                 maxlength="30"
                 v-charAndNumberLimit></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">设备名称：</p>
          <Input v-model="name"
                 placeholder="设备名称"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备类型：</p>
          <Select v-model="type"
                  placeholder="设备类型">
            <Option :value="item.id"
                    v-for="item in equipmentTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设备状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设备账号：</p>
          <Input v-model="account"
                 placeholder="设备账号"
                 maxlength="30"
                 v-accountLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设备密码：</p>
          <Input v-model="password"
                 placeholder="设备密码"
                 maxlength="30"
                 clearable></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">厂商-型号：</p>
          <Cascader :data="manufacturerArr"
                    v-model="manufacturer"></Cascader>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设备'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      number: '', // 设备编号
      communication: '', // 设备通讯号
      name: '', // 设备名称
      type: '', // 设备类型
      status: true, // 设备状态
      account: '', // 设备账号
      password: '', // 设备密码
      manufacturer: [], // 厂商-型号
      remark: '', // 备注
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'equipmentTypeArr': 'getEquipmentTypeAllList',
    }),
    manufacturerArr () {
      let arr = this.$store.getters.getEquipmentManufacturerAllEnableList
      return arr.map(item => {
        return {
          value: item.id,
          label: item.name,
          children: item.details.map(items => {
            return {
              value: items.id,
              label: items.name
            }
          })
        }
      })
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateEquipmentTypeAllList',
      'updateEquipmentManufacturerAllEnableList',
    ]),
    init () {
      // equipmentArchives已调用，防重复调用
      // this.updateEquipmentTypeAllList()
      this.updateEquipmentManufacturerAllEnableList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getEquipmentDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        this.status = res.status === '1'
        this.manufacturer = [res.manufacturer, res.model]
      })
    },
    // 点击确认
    onClickConfirm () {
      if (!this.number) return this.$Message.info('请输入设备编号');
      if (!this.communication) return this.$Message.info('请输入设备通讯号');
      if (!this.type) return this.$Message.info('请选择设备类型');
      let params = {
        number: this.number,
        communication: this.communication,
        name: this.name,
        type: this.type,
        account: this.account,
        password: this.password,
        remark: this.remark,
      }
      params.status = this.status ? '1' : '0'
      if (this.manufacturer.length > 0) {
        params.manufacturer = this.manufacturer[0]
        if (this.manufacturer.length > 1) {
          params.model = this.manufacturer[1]
        }
      }
      if (this.dataId) params.ids = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditEquipment', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.$emit('onClickConfirmErr', err)
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.number = ''
      this.communication = ''
      this.name = ''
      this.type = ''
      this.status = true
      this.account = ''
      this.password = ''
      this.manufacturer = []
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
