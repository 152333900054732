<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search"
           v-if="currentTabIndex!==3">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template v-if="currentTabIndex===0">
              <Input v-model="equipmentNumber"
                     placeholder="设备编号"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
              <Select v-model="bindObjectType"
                      placeholder="绑定对象类型"
                      clearable
                      @on-change="onChangeBindObjectType"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in bindObjectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect v-model="bindObjectName"
                            placeholder="绑定对象名称"
                            :disabled="!bindObjectType"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="bindObjectArr"
                            search
                            @onSelectChange="onChangeBindObject">
              </MyTreeSelect>
              <Select v-model="equipmentType"
                      placeholder="设备类型"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in equipmentTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="equipmentCommunication"
                     placeholder="设备通讯号"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
              <Input v-model="equipmentName"
                     placeholder="设备名称"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="manufacturer"
                      placeholder="厂商"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in manufacturerArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="equipmentModel"
                     placeholder="设备型号"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="equipmentNumber"
                     placeholder="设备编号"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
              <Select v-model="bindObjectType"
                      placeholder="绑定对象类型"
                      clearable
                      @on-change="onChangeBindObjectType"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in bindObjectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="bindObjectName"
                            placeholder="绑定对象名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="bindObjectArr"
                            :disabled="!bindObjectType"
                            search
                            @onSelectChange="onChangeBindObject">
              </MyTreeSelect>
              <Input v-model="equipmentModel"
                     placeholder="设备型号"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex!==3">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/equipmentArchives/query'])"
                      @click.stop="onClickSearch">
                <Icon custom="i-icon icon-sousuo"
                      size="16"></Icon>
                查询
              </Button>
              <Button class="m-l-10"
                      v-if="checkPermi(['/admin/equipmentArchives/query'])"
                      @click.stop="onClickReset">
                <Icon custom="i-icon icon-shuaxin"
                      size="16"></Icon>
                重置
              </Button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/equipmentArchives/add'])"
                    @click.stop="equipmentVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
            <Dropdown v-if="checkPermi(['/admin/equipmentArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            v-if="checkPermi(['/admin/equipmentArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        action='hss/tlequip/excelReader'
                        v-if="checkPermi(['/admin/equipmentArchives/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/equipmentArchives/delete','/admin/equipmentArchives/edit'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <!-- <DropdownItem name="settleLawsuit">批量解绑</DropdownItem> -->
                <DropdownItem name="restore"
                              v-if="checkPermi(['/admin/equipmentArchives/edit'])">批量启用</DropdownItem>
                <DropdownItem name="disable"
                              v-if="checkPermi(['/admin/equipmentArchives/edit'])">批量禁用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/equipmentArchives/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/equipmentArchives/add'])"
                    @click.stop="equipmentModelVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加型号
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/equipmentArchives/add'])"
                    @click.stop="equipmentTypeVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加类型
            </Button>
            <!-- <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <Button class="m-r-10">
              <Icon custom="i-icon icon-xiazai"
                    size="16"></Icon>
              导入
            </Button> -->
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/equipmentArchives/delete','/admin/equipmentArchives/edit'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <!-- <DropdownItem name="settleLawsuit">批量解绑</DropdownItem> -->
                <DropdownItem name="restore"
                              v-if="checkPermi(['/admin/equipmentArchives/edit'])">批量启用</DropdownItem>
                <DropdownItem name="disable"
                              v-if="checkPermi(['/admin/equipmentArchives/edit'])">批量禁用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/equipmentArchives/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Button type="text"
                  class="m-l-5"
                  @click.stop="equipmentManufacturerListVisible = true">设备厂商设置>></Button>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===1">
          <div class="tree-title">设备类型</div>
          <ul class="tree-list">
            <li class="tree-item"
                :class="{active:currentTreeId===item.id}"
                v-for="item in equipmentTypeArr"
                :key="item.id"
                @click.stop="onClickTreeItem(item.id)">{{item.name}}</li>
          </ul>
        </div>
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===2">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===1||currentTabIndex===2">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/edit']"
                          @click.stop="onClickEditEquipmentModel(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/edit']"
                          @click.stop="onClickStatusEquipmentModel(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/delete']"
                          @click.stop="onClickDeleteEquipmentModel(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===0">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/edit']"
                          @click.stop="onClickEditEquipment(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/edit']"
                          @click.stop="onClickChannel(row)"
                          v-if="row.equiptypeid==='视频设备'||row.equiptypeid==='车载监控'">通道设置</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/edit']"
                          @click.stop="onClickStatusEquipment(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/equipmentArchives/delete']"
                          @click.stop="onClickDeleteEquipment(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 添加设备 -->
    <EquipmentModal v-model="equipmentVisible"
                    :dataId.sync="equipmentId"
                    @onChange='onChangClearRowStyle'
                    @onClickConfirm="getList"
                    @onClickConfirmErr="faclStatusTip"></EquipmentModal>

    <!-- 添加型号 -->
    <EquipmentModelModal v-model="equipmentModelVisible"
                         :dataId.sync="equipmentModelId"
                         @onClickConfirm="getList"
                         @onChange='onChangClearRowStyle'></EquipmentModelModal>

    <!-- 添加类型 -->
    <EquipmentTypeModal v-model="equipmentTypeVisible"
                        :dataId.sync="equipmentTypeId"></EquipmentTypeModal>

    <!-- 视频通道 -->
    <EquipmentVideoChannelListModal v-model="equipmentVideoChannelListVisible"
                                    :dataId.sync="equipmentVideoChannelListId"
                                    @onClickConfirm="getList"
                                    @onChange='onChangClearRowStyle'></EquipmentVideoChannelListModal>

    <!-- 绑定对象 -->
    <EquipmentBindObjectModal v-model="equipmentBindObjectVisible"
                              :item.sync="equipmentBindObject"
                              @onClickConfirm="getList"
                              @onChange='onChangClearRowStyle'></EquipmentBindObjectModal>

    <!-- 协议配置 -->
    <EquipmentAgreementModal v-model="equipmentAgreementVisible"
                             :dataId.sync="equipmentAgreementId"></EquipmentAgreementModal>

    <!-- 厂商 -->
    <EquipmentManufacturerListModal v-model="equipmentManufacturerListVisible"></EquipmentManufacturerListModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
import MyTree from '@/components/common/MyTree'
import EquipmentModal from '@/components/product/admin/modal/archives/EquipmentModal'
import EquipmentModelModal from '@/components/product/admin/modal/archives/EquipmentModelModal'
import EquipmentTypeModal from '@/components/product/admin/modal/archives/EquipmentTypeModal'
import EquipmentVideoChannelListModal from '@/components/product/admin/modal/archives/EquipmentVideoChannelListModal'
import EquipmentBindObjectModal from '@/components/product/admin/modal/archives/EquipmentBindObjectModal'
import EquipmentAgreementModal from '@/components/product/admin/modal/archives/EquipmentAgreementModal'
import EquipmentManufacturerListModal from '@/components/product/admin/modal/archives/EquipmentManufacturerListModal'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { download } from '@/utils/utils'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect,
    MyTree,
    EquipmentModal,
    EquipmentModelModal,
    EquipmentTypeModal,
    EquipmentVideoChannelListModal,
    EquipmentBindObjectModal,
    EquipmentAgreementModal,
    EquipmentManufacturerListModal,
    ReaderFile,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '设备列表'
        },
        {
          name: '设备类型设置'
        },
        {
          name: '视频通道设置'
        },
        // {
        //   name: '协议配置'
        // },
      ],
      equipmentNumber: '',
      equipmentName: '',
      bindObjectType: '',
      bindObjectId: '',
      bindObjectName: '',
      bindObjectArr: [],
      equipmentType: '',
      equipmentCommunication: '',
      manufacturer: '',
      departId: '',
      equipmentModel: '',
      currentTreeId: 0,
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableWidths: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsEquipment: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设备编号',
          key: 'equipcode',
          width: 180,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '设备名称',
          key: 'equipname',
          width: 150,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          width: 120,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备通讯号',
          key: 'communicationcode',
          minWidth: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '型号-厂商',
          key: 'manufacturerid',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '协议类型',
          key: 'agreement',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定对象',
          key: 'bindobjname',
          minWidth: 80,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickBindObject(row)
                }
              }
            }, row.bindobjname ? row.bindobjname : '点击绑定');
          }
        },
        {
          title: '常用指令',
          key: 'orders',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsEquipmentType: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设备型号',
          key: 'equipmodel',
          minWidth: 60,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '协议',
          key: 'agreement',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturerid',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 80,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '常用指令',
          key: 'orders',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsVideo: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设备编号',
          key: 'equipcode',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '设备型号',
          key: 'equipmodel',
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定对象',
          key: 'bindname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '通道数量',
          key: 'details',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickChannel(row)
                }
              }
            }, row.details ? row.details.length : 0);
          }
        },
      ],
      // columnsAgreement: [
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '协议名称',
      //     key: 'manageName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '账号',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '密码',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '请求地址',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '操作',
      //     slot: 'operation',
      //     width: 80,
      //     align: 'center'
      //   }
      // ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增 / 修改设备
      equipmentVisible: false,
      equipmentId: '',
      // 新增 / 修改设备型号
      equipmentModelVisible: false,
      equipmentModelId: '',
      // 新增 / 修改设备类型
      equipmentTypeVisible: false,
      equipmentTypeId: '',
      // 视频通道
      equipmentVideoChannelListVisible: false,
      equipmentVideoChannelListId: '',
      // 绑定对象
      equipmentBindObjectVisible: false,
      equipmentBindObject: null,
      // 协议配置
      equipmentAgreementVisible: false,
      equipmentAgreementId: '',
      // 厂商
      equipmentManufacturerListVisible: false
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    bindObjectTypeArr () {
      let arr = [
        {
          id: 'emp',
          name: '人员'
        },
        {
          id: 'car',
          name: '车辆'
        },
      ]
      return arr.concat(this.$store.getters.getFacilityBigTypeAllList)
    },
    equipmentTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEquipmentTypeAllList)
    },
    manufacturerArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEquipmentManufacturerAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsEquipment
          break;
        case 1:
          columns = this.columnsEquipmentType
          break;
        case 2:
          columns = this.columnsVideo
          break;
        case 3:
          columns = this.columnsAgreement
          break;
        default:
          break;
      }
      return columns
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportEquipmentTem
          break;
        default:
          break;
      }
      return path
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportEquipment
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateFacilityBigTypeAllList',
      'updateDepartmentTreeList',
      'updateEquipmentTypeAllList',
      'updateEquipmentManufacturerAllList',
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.updateFacilityBigTypeAllList()
      this.updateDepartmentTreeList()
      this.updateEquipmentTypeAllList()
      this.updateEquipmentManufacturerAllList()
      this.getList()
    },
    // 修改绑定对象类型
    onChangeBindObjectType (value) {
      this.bindObjectId = ''
      this.bindObjectName = ''
      if (!value) {
        this.bindObjectArr = []
      } else {
        this.$http.getBindObjectTreeList({ treetype: value }).then(res => {
          if (res.code === 200) {
            this.bindObjectArr = res.result
          }
        })
      }
    },
    // 修改姓名
    onChangeBindObject (data) {
      if (data.type !== 'depart') {
        this.bindObjectId = data.id
        this.bindObjectName = data.name
      }
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    onClickTreeItem (id) {
      this.currentTreeId = id
      this.onClickSearch()
    },
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeleteEquipment(this.selectArr)
              break;
            case 1:
              this.onDeleteEquipmentModel(this.selectArr)
              break;
            default:
              break;
          }
          break;
        case 'disable':
          switch (this.currentTabIndex) {
            case 0:
              this.$Modal.confirm({
                title: '禁用',
                content: '确认禁用?',
                onOk: () => {
                  let params = {
                    ids: this.selectArr.join(),
                    status: 0
                  }
                  this.$store.dispatch('createOrEditEquipment', params).then(res => {
                    this.getList()
                  }).catch(err => {
                    this.faclStatusTip(err)
                    // this.$Message.info(err.message)
                  })
                }
              })
              break;
            case 1:
              this.$Modal.confirm({
                title: '禁用',
                content: '确认禁用?',
                onOk: () => {
                  let params = {
                    ids: this.selectArr.join(),
                    status: 0
                  }
                  this.$store.dispatch('createOrEditEquipmentModel', params).then(res => {
                    this.getList()
                  }).catch(err => {
                    this.$Message.info(err.message)
                  })
                }
              })
              break;
            default:
              break;
          }
          break;
        case 'restore':
          switch (this.currentTabIndex) {
            case 0:
              this.$Modal.confirm({
                title: '启用',
                content: '确认启用?',
                onOk: () => {
                  let params = {
                    ids: this.selectArr.join(),
                    status: 1
                  }
                  this.$store.dispatch('createOrEditEquipment', params).then(res => {
                    this.getList()
                  }).catch(err => {
                    this.$Message.info(err.message)
                  })
                }
              })
              break;
            case 1:
              this.$Modal.confirm({
                title: '启用',
                content: '确认启用?',
                onOk: () => {
                  let params = {
                    ids: this.selectArr.join(),
                    status: 1
                  }
                  this.$store.dispatch('createOrEditEquipmentModel', params).then(res => {
                    this.getList()
                  }).catch(err => {
                    this.$Message.info(err.message)
                  })
                }
              })
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getEquipmentList()
          break;
        case 1:
          this.getEquipmentModelList()
          break;
        case 2:
          this.getVideoEquipmentList()
          break;
        case 3:
          // this.getPositionList()
          break;
        default:
          break;
      }
    },
    getEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        bindtype: this.bindObjectType,
        equipcode: this.equipmentNumber,
        equipname: this.equipmentName,
        bindobjid: this.bindObjectId,
        equiptypeid: '',
        communicationcode: this.equipmentCommunication
      }
      if (this.equipmentType) params.equiptypeid = this.equipmentType
      this.tableLoading = true
      this.$http.getEquipmentList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.equiplist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getEquipmentModelList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        manufacturerid: '',
        equipmodel: this.equipmentModel,
        equiptypeid: ''
      }
      if (this.manufacturer) params.manufacturerid = this.manufacturer
      if (this.currentTreeId) params.equiptypeid = this.currentTreeId
      this.tableLoading = true
      this.$http.getEquipmentModelList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.equipmodellist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getVideoEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: this.bindObjectType,
        equipcode: this.equipmentNumber,
        bindobjid: this.bindObjectId,
        equipmodel: this.equipmentModel
      }
      this.tableLoading = true
      this.$http.getVideoEquipmentList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.equiplist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.selectArr = []
      this.equipmentNumber = ''
      this.equipmentName = ''
      this.bindObjectType = ''
      this.bindObjectId = ''
      this.bindObjectName = ''
      this.bindObjectArr = []
      this.equipmentType = ''
      this.equipmentCommunication = ''
      this.manufacturer = ''
      this.equipmentModel = ''
      this.currentTreeId = 0
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 点击绑定对象
    onClickBindObject (row) {
      this.equipmentBindObject = row
      this.equipmentBindObjectVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击通道
    onClickChannel (row) {
      this.equipmentVideoChannelListId = row.id
      this.equipmentVideoChannelListVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击修改设备
    onClickEditEquipment (row) {
      this.equipmentId = row.id
      this.equipmentVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击删除设备
    onClickDeleteEquipment (row) {
      this.onDeleteEquipment([row.id])
      this.selectVisitedArr.push(row)
    },
    onDeleteEquipment (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '当设备绑定了对象后删除将自动解绑绑定关系',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchEquipment(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.selectArr = []
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    faclStatusTip (err) {
      let arr = err.message.split('##')
      let cont = `
      <div>
          ${arr.map((item) => {
        return `<div>${item}</div>`
      }).join('')}
        </div>
      `
      setTimeout(() => {
        console.log(1111);
        this.$Modal.error({
          title: '错误',
          content: cont,
        })
      }, 300);
    },
    // 修改设备状态
    onClickStatusEquipment (row) {
      this.selectVisitedArr.push(row)
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditEquipment', params).then(res => {
              this.getList()
            }).catch(err => {
              // console.log(err);
              this.faclStatusTip(err)
              // this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditEquipment', params).then(res => {
              this.getList()
            }).catch(err => {
              // this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 点击修改设备型号
    onClickEditEquipmentModel (row) {
      this.equipmentModelId = row.id
      this.equipmentModelVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击删除设备型号
    onClickDeleteEquipmentModel (row) {
      this.onDeleteEquipmentModel([row.id])
      this.selectVisitedArr.push(row)
    },
    onDeleteEquipmentModel (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除？',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchEquipmentModel(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
              this.$store.dispatch('updateEquipmentTypeEnableAllList', true)
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改设备状态型号
    onClickStatusEquipmentModel (row) {
      this.selectVisitedArr.push(row)
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditEquipmentModel', params).then(res => {
              this.$store.dispatch('updateEquipmentManufacturerAllEnableList', true)
              this.$store.dispatch('updateEquipmentTypeEnableAllList', true)
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditEquipmentModel', params).then(res => {
              this.$store.dispatch('updateEquipmentManufacturerAllEnableList', true)
              this.$store.dispatch('updateEquipmentTypeEnableAllList', true)
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            bindtype: this.bindObjectType,
            equipcode: this.equipmentNumber,
            equipname: this.equipmentName,
            bindobjid: this.bindObjectId,
            equiptypeid: '',
            communicationcode: this.equipmentCommunication
          }
          if (this.equipmentType) data.equiptypeid = this.equipmentType
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;

      .tree-title {
        background: #f5f5f5;
        font-size: 14px;
        color: #25bb96;
        padding: 15px 0 15px 12px;
        font-weight: bold;
      }

      .tree-list {
        flex: 1;
        overflow: auto;
        .tree-item {
          padding: 0 8px;
          line-height: 36px;
          cursor: pointer;

          &:hover {
            background: #f0f3f7;
          }

          &.active {
            background: #f0f3f7;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>